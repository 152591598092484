<template>

<div class="d-flex align-items-center mb-3">
<h1 class="page-header mb-0">Manage Collections</h1>
<div class="ms-auto">
    <button class="btn btn-primary" v-if="loading == 'update_search_meta'" >Updating Info <i class="fa fa-spinner fa-spin"></i></button>
    <button class="btn btn-primary" v-else @click.prevent="comp_update_search_meta" >Update Search Meta </button>
</div>
</div>
<div class="mb-md-4 mb-3 d-md-flex">
<div class="mt-md-0 mt-2"><a href="#" class="text-dark text-decoration-none"><i class="fa fa-download fa-fw me-1 text-muted"></i> Export</a></div>
<div class="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
<a href="#" data-bs-toggle="dropdown" class="text-dark text-decoration-none">More Actions</a>
<div class="dropdown-menu">
<a class="dropdown-item" href="#">Action</a>
<a class="dropdown-item" href="#">Another action</a>
<a class="dropdown-item" href="#">Something else here</a>
<div role="separator" class="dropdown-divider"></div>
<a class="dropdown-item" href="#">Separated link</a>
</div>
</div>
</div>





<div class="modal-overlay hideall" id="updatecollectiondata">
  <div class="modal-container">
    <div class="card  mb-4" v-if="collection" id="updatecollectiondata">
        <div class="card-header"><h5 class="card-title">Update collection Data</h5></div>
            <div class="card-body">
                <form @submit.prevent="updateforminput" >
                <div class="form-group row mb-3">
                <div class="col-sm-4">
                    <label class="form-label" for="exampleFormControlInput1">First Name</label>
                    <input type="text" v-model="collection.f_name" class="form-control" id="exampleFormControlInput1" placeholder="Enter First Name" required>
                    <i class="text-warning" v-if="formerror.f_name">{{ formerror.f_name }}</i>
                </div>
        
                <div class="col-sm-4">
                    <label class="form-label" for="exampleFormControlInput1">Last Name</label>
                    <input type="text" v-model="collection.l_name" class="form-control" id="exampleFormControlInput1" placeholder="Enter Last Name" required>
                    <i class="text-warning" v-if="formerror.l_name">{{ formerror.l_name }}</i>
                </div>


                <div class="col-sm-4">
                    <label class="form-label" for="exampleFormControlInput1">Email Address</label>
                    <input type="email" v-model="collection.email" class="form-control" id="exampleFormControlInput1" placeholder="Enter Email Address">
                    <i class="text-warning" v-if="formerror.email">{{ formerror.email }}</i>
                </div>

                <div class="col-sm-4 mt-2">
                    <label class="form-label" for="exampleFormControlInput1">Phone Number</label>
                    <input type="number" v-model="collection.phone" class="form-control" id="exampleFormControlInput1" placeholder="Enter   Phone Number" required>
                    <i class="text-warning" v-if="formerror.phone">{{ formerror.phone }}</i>
                </div>
        
                <div class="col-sm-4 mt-2">
                    <label class="form-label" for="exampleFormControlInput1">Whatsapp Number</label>
                    <input type="number" v-model="collection.whatsapp" class="form-control" id="exampleFormControlInput1" placeholder="Whatsapp Number">
                    <i class="text-warning" v-if="formerror.whatsapp">{{ formerror.whatsapp }}</i>
                </div>


                <div class="col-sm-4 mt-2">
                    <label class="form-label" for="exampleFormControlInput1">Date of Birth</label>
                    <input type="text" v-model="collection.dob" class="form-control" id="exampleFormControlInput1" placeholder="Enter Date of Birth">
                    <i class="text-warning" v-if="formerror.dob">{{ formerror.dob }}</i>
                </div>

                <div class="col-sm-4 mt-2">
                    <label class="form-label" for="exampleFormControlInput1">Select Gender</label>
                    <select  v-model="collection.gender_id" class="form-control"  placeholder="Select Genders" v-if="genders">
                        <option value="">Select Gender</option>
                        <option :value="gen.id" v-for="gen in genders.data">{{  gen.title }}</option>
                    </select>
                    <i class="text-warning" v-if="formerror.gender_id">{{ formerror.gender_id }}</i>
                </div>


                <div class="col-sm-4 mt-2" style="position:relative" v-if="professions">
                    <label class="form-label" for="exampleFormControlInput1">Select Collection Profession</label>
                    <div style="position:relative">
                    <input class="form-control" v-model.trim="userprofession" @input="createprofessionsearchlist" placeholder="Enter the Name of the Profession..." />
                    <div v-if="loading == 'getsearchedprofessions'" style="position:absolute; right:15px; bottom:5px"><i class="fas fa-circle-notch fa-spin" style="font-size:20px"></i></div>
                    </div>
                    <div class="hideall" id="professionlist">
                    <div class="list-group scrollbarslect" style="position:absolute; width:97% !important; z-index:999999999999999999999999999 !important; height:200px; overflow:auto"  v-if="professions.data.length">
                        <a href="#" v-for="profession in professions.data" :key="profession" @click.prevent="selectprofessionforupdate(profession)" :class="{'active': collection.profession_id == selectedprofession_id}" class="list-group-item list-group-item-action">{{ profession.title }}</a>
                    </div>
                    <div class="list-group" style="position:absolute;" v-else>
                        <a href="#"  class="list-group-item list-group-item-action">No professions found with that title. Try a different Search</a>
                    </div>
                    </div>
                
                    <i class="text-warning" v-if="formerror.profession_id">{{ formerror.profession_id }}</i>
                </div>

                <div class="col-sm-4 mt-2" style="position:relative" v-if="villages">
                    <label class="form-label" for="exampleFormControlInput1">Select Collection Village</label>
                    <div style="position:relative">
                    <input class="form-control" v-model.trim="uservillage" @input="createvillagesearchlist" placeholder="Enter the Name of the Village..." />
                    <div v-if="loading == 'getsearchedvillages'" style="position:absolute; right:15px; bottom:5px"><i class="fas fa-circle-notch fa-spin" style="font-size:20px"></i></div>
                    </div>
                    <div class="hideall" id="villagelist">
                    <div class="list-group scrollbarslect" style="position:absolute; width:97% !important; z-index:999999999999999999999999999 !important; height:200px; overflow:auto"  v-if="villages.data.length">
                        <a href="#" v-for="village in villages.data" :key="village" @click.prevent="selectvillageforupdate(village)" :class="{'active': collection.village_id == selectedvillage_id}" class="list-group-item list-group-item-action">{{ village.title }}({{ village.division.title }} - {{ village.district.title }})</a>
                    </div>
                    <div class="list-group" style="position:absolute;" v-else>
                        <a href="#" @click.prevent="selectvillage" class="list-group-item list-group-item-action">No Villages found with that Name. Try a different Search</a>
                    </div>
                    </div>
                
                    <i class="text-warning" v-if="formerror.village_id">{{ formerror.village_id }}</i>
                </div>
                
            </div>

            <div  style="float:right" >
                <button type="submit" @click.prevent="cancelprocess" class="btn btn-danger btn-block" style="margin-right:6px">Close Form</button>
                <button type="submit" class="btn btn-indigo btn-block">Update Collection information </button>
            </div>
            </form>
        
        
            </div>
    </div>
  </div>
</div>
    

    
    <div class="row">
    
    <div class="card">
    <ul class="nav nav-tabs nav-tabs-v2 px-4">
    <li class="nav-item me-3"><a href="#allTab"  class="nav-link  px-2" :class="{'active' : filtertype == 'all'}"  @click.prevent="changefilterlist('all')"  data-bs-toggle="tab">All</a></li>
    <li class="nav-item me-3"><a href="#publishedTab" class="nav-link px-2" :class="{'active' : filtertype == 'active'}" @click.prevent="changefilterlist('active')">Active </a></li>
    <li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2"   :class="{'active' : filtertype == 'suspended'}" @click.prevent="changefilterlist('suspended')">Suspended</a></li>
    <li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2"   :class="{'active' : filtertype == 'validated'}" @click.prevent="changefilterlist('validated')">Validated</a></li>
    <li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2"   :class="{'active' : filtertype == 'unvalidated'}" @click.prevent="changefilterlist('unvalidated')">Non Validated</a></li>
    </ul>
    
    
    <div class="tab-content p-4">
    <div class="tab-pane fade show active" id="allTab">
    
    <div class="input-group mb-4">
    <div class="flex-fill position-relative">
    <div class="input-group">
    <input type="text" v-model="search" class="form-control ps-35px" placeholder="Search by Name" />
    <div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style="z-index: 1020;">
    <i class="fa fa-search opacity-5"></i>
    </div>
    </div>
    </div>
    </div>


    <div class="row">
    <div class="col-md-1 col-sm-2">
        <select v-model="per_page" class="form-control" @change="getpagedatalist_perpage">
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
        </select>

    </div>
    <div class="col-md-11 col-sm-10">


        <div class="input-group mb-4">
            <div class="flex-fill position-relative">
                <input type="search" @input="comp_search_page_data(current_page)"  v-model="search_filter"  class="form-control " placeholder="Search Collections" />
              
            </div>

            <button class="btn btn-default dropdown-toggle" @click.prevent="getpagedatalist(current_page)"><span class="d-none d-md-inline">Refresh Page Data</span><span class="d-inline d-md-none"><i class="fa fa-check"></i></span></button>

        </div>
    </div>

</div>

    
    
    
    <div class="table-responsive">
    <table class="table table-hover text-nowrap">
    <thead>
    <tr>
    <th class="border-top-0 pt-0 pb-2">#</th>
    <th class="border-top-0 pt-0 pb-2">Collection Name</th>
    <th class="border-top-0 pt-0 pb-2">Resident Village</th>
    <th class="border-top-0 pt-0 pb-2">Profession</th>
    <th class="border-top-0 pt-0 pb-2">Phone</th>
    <th class="border-top-0 pt-0 pb-2">Submited By</th>
    <th class="border-top-0 pt-0 pb-2">Validated</th>
    <th class="border-top-0 pt-0 pb-2">Status</th>
    <th class="border-top-0 pt-0 pb-2">Action</th>
    </tr>
    </thead>
    <tbody v-if="collections">
    
    <tr v-for="(item, index) in filteredlist" :key="item.id" style="cursor:pointer !important" >
    
        <td class="align-middle">{{ ((current_page - 1) * per_page) + index + 1}}</td>
        <td class="align-middle" ><router-link class="tooltipcontact" :to="{name:'collectiondetails', params:{id:item.id}}">{{ item.f_name }} {{ item.l_name }}
        <span>
            
            <div class="row">
                <div class="col-md-6">
                    <ul style="text-align:left">
                        <li>Name: {{ item.name }}</li>
                        <li>Phone: {{ item.phone }}</li>
                        <li>Email: {{ item.email }}</li>
                        <li>Date of Birth: {{ item.dob }}</li>
                        <li>Whatsapp Number: {{ item.whatsapp }}</li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <ul style="text-align:left">
                        <li>Village: {{ item.village?.title ?? 'Not Found' }}</li>
                        <li>Parish: {{ item.parish?.title ?? 'Not Found' }}</li>
                        <li>SubCounty: {{ item.division?.title ?? 'Not Found' }}</li>
                        <li>District: {{ item.district?.title ?? 'Not Found'  }}</li>
                        <li>Country: {{ item.country?.title ?? 'Not Found' }}</li>
                    </ul>
                </div>
            </div>
            
        </span>
        </router-link>
        </td>
        <td class="align-middle" >{{ item.village?.title }}- {{ item.country?.title }}</td>
        <td class="align-middle" >{{ item.profession?.title }}</td>
        <td class="align-middle" >{{ item.phone }}</td>
        <td class="align-middle" >{{ item.user?.name }}</td>
        <td class="align-middle" >
            <button v-if="item.verify == 1"  class="badge btn btn-success  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Validated</button>
            <button v-else  @click.prevent="validatecontact(item.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Validate</button>
        </td>
        <td class="align-middle" >
            <button v-if="item.status == 1" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-teal  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Hide</button>
            <button v-if="item.status == 0" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Unhide</button>
        </td>
    
    
        <td class="align-middle">
      
        <button @click.prevent="getcollectionforupdate(item)"  class="badge btn btn-warning  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Update</button>
        <button @click.prevent="getcollectionfordelete(item.id)"  class="badge btn btn-danger  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Delete</button>
       </td>
    
    </tr>
    
    
    
    
    </tbody>
    </table>
    </div>
    
    


    <div class="d-md-flex align-items-center" v-if="collections">
    <div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
    Showing {{ collections.from }} to {{ collections.to }} of {{ collections.total }} entries
    </div>

    <nav >
    <ul class="pagination mb-0 justify-content-center">
        <li class="page-item " :class="{'disabled' : current_page < 2}">
        <button class="page-link" href="#" tabindex="-1"  @click.prevent="getpagedatalist(current_page - 1)" >Previous</button>
        </li>
        
        <li class="page-item active">
        <a class="page-link" href="#">Current Page : {{ current_page }}</a>
        </li>
    
        <li class="page-item" :class="{'disabled' : current_page == collections.last_page}">
        <button class="page-link"  @click.prevent="getpagedatalist(current_page + 1)"  href="#">Next</button>
        </li>
    </ul>
    </nav>

    </div>
    
    
    
    </div>
    </div>
    </div>
    
    
    
    </div>
    
    
    </template>
    <script>
    import {mapGetters, mapActions } from 'vuex'
    export default {
    
        data(){
            return{
                forminput:{
                    title: '',
                    zipcode:'',
                },
                formerror:{
                    f_name: '',
                    l_name:'',
                    email: '',
                    phone:'',
                    whatsapp: '',
                    dob:'',
                    village_id:'',
                    profession_id:'',
                    gender_id:''
                },
                collection:{},
                uservillage:'',
                selectedvillage_id:'',
                userprofession:'',
                selectedprofession_id:'',

                filtertype:'',
                search_filter:'',
                current_page:1,
                sort_field:'',
                sort_order:'desc',
                per_page:50,
                search:'',
                status:'',
                
            }
        },
    
 
         computed:{
            ...mapGetters(['msg', 'error','pageloader','loading', 'collections','villages','professions','genders']),
    
                filteredlist(){
                    if(this.filtertype == 'active'){
                        return this.collections.data.filter(co => co.status == 1)
                    }else if(this.filtertype == 'suspended'){
                        return this.collections.data.filter(co => co.status == 0)
                    }else if(this.filtertype == 'unvalidated'){
                        return this.collections.data.filter(co => co.verify == 0)
                    }else if(this.filtertype == 'validated'){
                        return this.collections.data.filter(co => co.verify == 1)
                    }else {
                        return this.collections.data
                    }
                },
            },
    
    
        methods:{
            ...mapActions(['savecollection','getcollectionlist', 'getcollection','updatecollection','getserachedcollections','changecollectionstatus','validatecollection','getsearchedvillages','getvillagelist','getprofessionlist','getsearchprofessionslist','deletecollection','getsearchedcollections','update_search_meta','getgenderlist']),


            comp_update_search_meta(){
                let data = {}
                this.update_search_meta(data).then((()=>{

                }))
            },

            getpagedatalist_perpage(){
                let page = 1;
                this.getpagedatalist(page);
            },

            getpagedatalist(page){
                this.current_page = page;
                let data = {
                    sort_field:this.sort_field,
                    sort_order:this.sort_order,
                    per_page:this.per_page,
                    search:this.search,
                }
                this.getcollectionlist({data, page});
            },

            comp_search_page_data(page){
                if(this.search_filter){
                    if(this.search_filter.length > 2){
                        this.search = this.search_filter
                        this.current_page = 1;
                        this.getpagedatalist(this.current_page)
                    }else{
                        this.current_page = 1;
                        this.getpagedatalist(this.current_page)
                    }
                }
            },


            changefilterlist(value){
                this.filtertype = value;
            },
    
            searchbtncollections(){
                  if(this.search == ''){
                  }else{
                      let data = {
                        search: this.search
                        }
                        
                        this.getsearchedcollections(data);
                  }   
            },

            createvillagesearchlist(){
                if(this.uservillage.length){
                    $('#villagelist').removeClass('hideall')
                    if(this.uservillage.length > 1){
                        this.getserachvillageslist()
                    }else{
                        this.getserachvillageslist()
                    }
    
                }else{
                    this.getvillagelist()
                }
            },

            getserachvillageslist(){
                  if(this.uservillage == ''){
                  }else{
                      let data = {
                        search: this.uservillage
                        }
                        
                        this.getsearchedvillages(data);
                  }   
            },

            selectvillageforupdate(value){
               this.uservillage = value.title;
               this.selectedvillage_id = value.id;
               this.collection.village_id = value.id
               $('#villagelist').addClass('hideall')
            },


            ///professions
            createprofessionsearchlist(){
                if(this.userprofession.length){
                    $('#professionlist').removeClass('hideall')
                    if(this.userprofession.length > 1){
                        this.getserachprofessionslist()
                    }else{
                        this.getserachprofessionslist()
                    }
    
                }else{
                    this.getprofessionlist()
                }
            },

            getserachprofessionslist(){
                  if(this.userprofession == ''){
                  }else{
                      let data = {
                        search: this.userprofession
                        }
                        
                        this.getsearchprofessionslist(data);
                  }   
            },

            selectprofessionforupdate(value){
               this.userprofession = value.title;
               this.selectedprofession_id = value.id;
               this.collection.profession_id = value.id
               $('#professionlist').addClass('hideall')
            },



            updateforminput(){
                let id = this.collection.id;
                if(this.collection.f_name == ''){
                this.formerror.f_name = 'What is the User Profession';
                setTimeout(() => this.collection.f_name = '', 5000)
                }else if(this.collection.l_name == ''){
                this.formerror.l_name = 'What is the User Profession';
                setTimeout(() => this.collection.l_name = '', 5000)
                }else if(this.collection.phone == ''){
                this.formerror.phone = 'Phone Number is Required';
                setTimeout(() => this.collection.phone = '', 5000)
                }else if(this.collection.gender_id == ''){
                this.formerror.gender_id = 'Select Collection Gender';
                setTimeout(() => this.collection.gender_id = '', 5000)
                }else if(this.collection.profession_id == ''){
                this.formerror.profession_id = 'What is the User Profession';
                setTimeout(() => this.collection.profession_id = '', 5000)
                }else if(this.collection.parish_id == ''){
                this.formerror.village_id = 'Select a Village you Stay';
                setTimeout(() => this.formerror.village_id = '', 5000)
                }else{
                    let data = {
                        f_name:this.collection.f_name,
                        l_name:this.collection.l_name,
                        email:this.collection.email,
                        phone:this.collection.phone,
                        whatsapp:this.collection.whatsapp,
                        dob:this.collection.dob,
                        profession_id:this.collection.profession_id,
                        gender_id:this.collection.gender_id,
                        village_id:this.collection.village_id,
                    
                    }
                this.updatecollection({data, id}).then(()=>{
                    let page = this.currentpage;
                    this.getcollectionlistpage(page)
                    this.cancelprocess();
                })
                }
               
            },



            validatecontact(id){
                this.validatecollection(id).then((response)=>{
                    let page = this.currentpage;
                    this.getcollectionlistpage(page)
                })
            },
    
    
            getcollectionforupdate(collect){
                if(collect){ 
                    this.collection = Object.assign(collect) 
                    this.getvillagelist();
                    this.getprofessionlist();
                    this.userprofession = this.collection.profession?.title;
                    this.uservillage = this.collection.village?.title;
                    $('#updatecollectiondata').removeClass('hideall') 
                    this.showupdateform(); 
                }
            },


            getcollectionfordelete(id){
                this.deletecollection(id).then(()=>{
                    let page = this.currentpage;
                    this.getcollectionlistpage(page)
                    $('#updatecollectiondata').removeClass('hideall')
                    this.showupdateform();
                })
            },
    
    
            changeitemstatusbtn(id){
                this.changecollectionstatus(id).then(()=>{
                    let page = this.currentpage;
                    this.getcollectionlistpage(page)
                })
            },
    
    
            showcreateformform(){
                $('#updateoldform').addClass('hideall')
                $('#createnewbtn').addClass('hideall')
                $('#createnewform').removeClass('hideall')
                $('#closebtn').removeClass('hideall')
            },
    
            showupdateform(id){
                $('#updateoldform').removeClass('hideall')
                $('#createnewbtn').addClass('hideall')
                $('#createnewform').addClass('hideall')
                $('#closebtn').removeClass('hideall')
            },
    
    
            cancelprocess(){
                $('#updatecollectiondata').addClass('hideall')
            },
        },
    
        
    
        created(){
            let page = this.current_page;
            this.getpagedatalist(page)
            this.getgenderlist()
        }
    
    }
    </script>
    <style>
.modal-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); 
  display: flex;
  justify-content: center;
  align-items: center;
}


.modal-container {

  width: 60vw;
  padding: 20px;
  border-radius: 5px;

}

.scrollbarslect {
    scrollbar-width: auto;
    scrollbar-color: #221f23 #ffffff;
  }

.scrollbarslect::-webkit-scrollbar {
    width: 16px;
  }

.scrollbarslect::-webkit-scrollbar-track {
    background: #ffffff;
  }

.scrollbarslect::-webkit-scrollbar-thumb {
    background-color: #221f23;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }



  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  .scrollpaginate {
    width:50%;
    overflow: scroll;
    scrollbar-width: auto;
    scrollbar-color: #8f54a0 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .scrollpaginate::-webkit-scrollbar {
    height: 0px;
  }

  .scrollpaginate::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .scrollpaginate::-webkit-scrollbar-thumb {
    background-color: #8f54a0;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  .tooltipcontact:hover span {
    opacity: 1;
    filter: alpha(opacity=100);
    left: 20em;
    z-index: 99;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    }
    
    .box b {
      color: #fff;
    }
    
    .tooltipcontact span {
        background: none repeat scroll 0 0 #101829; /*-- some basic styling */
        color: #fff;
        font-family: 'Helvetica';
        font-size: 0.8em;
        font-weight: normal;
        line-height: 1.5em;
        padding: 16px 15px;
        width: 40%;
        height: 120px;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: hidden;
        left: 20em;
        margin-left: 0;	
        /*-- set opacity to 0 otherwise our animations won't work */
        opacity: 0;
        filter: alpha(opacity=0);  	
        position: absolute;
        text-align: center;	
        z-index: 2;
        text-transform: none;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease-in-out;
    }
    
    .tooltipcontact span:after {
        border-color: #101829 rgba(0, 0, 0, 0);
        border-style: solid;
        border-width: 15px 15px 0;
        bottom: -15px;
        content: "";
        display: block;
        left: 31px;
        position: absolute;
        width: 0;
    }
    </style>